import React, { useState } from "react";
import Layout from "./components/Layout/Layout";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Login from "./pages/auth/Login";
// import Upload from "./pages/Upload";
import FileUpload from "./pages/FileUpload";
import FileList from "./pages/FileList";
import FileDownload from "./pages/FileDownload";
import AdminLogin from "./pages/auth/AdminLogin";
import LiveStream from "./pages/LiveStream";

function App() {
  const [isAuthenticated, setAuthenticated] = useState(false);
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />} />
        <Route
          path="/admin-login"
          element={<AdminLogin setAuthenticated={setAuthenticated} />}
        />
        <Route
          path="/upload"
          element={
            isAuthenticated ? <FileUpload /> : <Navigate to="/admin-login" />
          }
        />
        <Route path="/login" element={<Login />} />
        {/* <Route path="/upload" element={<FileUpload />} /> */}
        <Route path="/files" element={<FileList />} />
        <Route path="/download" element={<FileDownload />} />
        <Route path="/live" element={<LiveStream />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
