// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
const firebaseConfig = {
  apiKey: "AIzaSyBwRBvENuhsIiWz4ifUXfzOjl4OfJZgPOo",
  authDomain: "mary-mercy-disciples.firebaseapp.com",
  projectId: "mary-mercy-disciples",
  storageBucket: "mary-mercy-disciples.appspot.com",
  messagingSenderId: "139591783054",
  appId: "1:139591783054:web:95c9acc81de5a15fe06fdf",
  measurementId: "G-1LJZ1RS41C",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const googleProvider = new GoogleAuthProvider();
