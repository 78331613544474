import React from "react";
import SignIn from "./SignIn";
import Header from "../../components/UI/Header/Header";
import Footer from "../../components/UI/Footer/Footer";

const Login = () => {
  return (
    <>
      <Header />
      <SignIn />
      <Footer />
    </>
  );
};

export default Login;
