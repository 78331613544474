import React, { useState } from "react";
import axios from "axios";
import Header from "../components/UI/Header/Header";
import Footer from "../components/UI/Footer/Footer";
import toast from "react-hot-toast";

const FileUpload = () => {
  const [file, setFile] = useState(null);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleFileUpload = async () => {
    if (!file) {
      toast.error("Please select a file first");
      // alert("Please select a file first");
      return;
    }

    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await axios.post(
        "https://server.marymercydisciples.com/upload",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      // alert("File uploaded successfully: " + response.data.data);
      toast.success("file successfully uploaded");
    } catch (error) {
      // console.error("Error uploading file:", error);
      // alert("Error uploading file");
      toast.error("error uploading file");
    }
  };

  return (
    <>
      <Header />
      <div style={{ marginTop: "200px" }}>
        <h2>Upload a File</h2>
        <input type="file" onChange={handleFileChange} />
        <button onClick={handleFileUpload}>Upload</button>
      </div>
      <Footer />
    </>
  );
};

export default FileUpload;
