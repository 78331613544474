// src/components/AdminLogin.js
import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Header from "../../components/UI/Header/Header";
import Footer from "../../components/UI/Footer/Footer";
import toast from "react-hot-toast";

const AdminLogin = ({ setAuthenticated }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "https://server.marymercydisciples.com/admin",
        {
          username,
          password,
        }
      );
      console.log("Response data:", response.data);
      if (response.data.success) {
        setAuthenticated(true);
        toast.success("Logged in as admin");
        console.log("Login success");
        navigate("/upload");
      } else {
        toast.error("Check your inputs again");
        setError("Invalid credentials");
        console.log("Invalid credentials");
      }
    } catch (err) {
      setError("Check the inputs and try again.");
      toast.error("Check the inputs and try again");
      console.error("Login error:", err);
    }
  };

  const placeCenter = { marginTop: "100px", marginBottom: "100px" };

  return (
    <>
      <Header />
      <div className="container" style={placeCenter}>
        <h2>Admin Login</h2>
        <form onSubmit={handleLogin}>
          <p className="form-tittle">Sign In as Admin to make uploads</p>
          <div className="input-container">
            <input
              type="text"
              placeholder="Enter a username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
          </div>
          <div className="input-container">
            <input
              type="password"
              placeholder="Enter a password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          {error && <p>{error}</p>}
          <button type="submit" className="submit">
            Login
          </button>
        </form>
      </div>
      <Footer />
    </>
  );
};

export default AdminLogin;
