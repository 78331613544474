import React, { useState } from "react";

import Section from "../../../HOC/Section";
import aboutImage from "../../../assets/img/about.jpg";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "reactstrap";

const About = () => {
  const [open, setOpen] = useState("1");
  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };
  return (
    <Section id="about">
      <div className="container pt-2 pb-5">
        <div className="section-header pt-5 pb-5 text-center">
          <h3 className="section-title">
            <span>What </span>We Do
          </h3>
          <h6 className="section-subtitle mr-auto ml-auto">
            Individualized quality care that meets the total needs of the
            patient Individualized quality care that quality care that
            Individualized quality care that meets the total.
          </h6>
        </div>
        <div className="section-content">
          <div className="row">
            <div className="col-md-12 col-lg-6 mb-3">
              <div className="aboutImage">
                <img src={aboutImage} alt="about company" />
              </div>
            </div>
            <div className="col-md-12 col-lg-6">
              <h3 className="about-title">About us</h3>
              <div className="about-description">
                <p>
                  Mary Mercy disciples, International disciples seminary, We
                  offer the following programmes with free certificate.
                </p>
                <div>
                  <Accordion open={open} toggle={toggle}>
                    <AccordionItem>
                      <AccordionHeader targetId="1">
                        Leading Church Worship
                      </AccordionHeader>
                      <AccordionBody accordionId="1">
                        We inculcate and teaches the act of leading worship
                        services within a church,this involves leading hymns,
                        prayers, and sermons, as well as organizing and planning
                        worship services. The worship leader is typically
                        responsible for creating a worship experience that is
                        engaging, meaningful, and aligned with the church’s
                        theological and cultural values. We train the worship
                        leader on how to plan and execute worship services such
                        as selecting and arranging songs, choosing readings and
                        prayers, and coordinating the use of multimedia elements
                        like slideshows or videos. Our objective : Is to create
                        an environment where worshipers can connect with God and
                        with one another. This involves setting a positive and
                        inclusive tone, encouraging participation and
                        engagement, and providing opportunities for worshipers
                        to express their faith.
                      </AccordionBody>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionHeader targetId="2">
                        Children's Mission
                      </AccordionHeader>
                      <AccordionBody accordionId="2">
                        We teach on how to organize outreach program that
                        focuses on children and youth and those programs are
                        designed to share the message of Christianity and
                        provide spiritual, educational, and emotional support to
                        children in various settings, such as schools, churches,
                        or community centers. Child missions often involve
                        activities such as Bible studies, worship services,
                        prayer groups, and other forms of religious education.
                        They may also include outreach programs, such as feeding
                        programs, medical clinics, and disaster relief efforts,
                        which aim to meet the physical and emotional needs of
                        children while sharing the message of Christianity.
                        Child missions are typically conducted by Christian
                        organizations, churches, or individual believers who are
                        Our objective: This to create and inculcate the
                        passionate about reaching out to children and youth with
                        the love of Jesus Christ.
                      </AccordionBody>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionHeader targetId="3">
                        Youth Mission 3 Evangelism
                      </AccordionHeader>
                      <AccordionBody accordionId="3"></AccordionBody>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionHeader targetId="4">Pioneer</AccordionHeader>
                      <AccordionBody accordionId="4"></AccordionBody>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionHeader targetId="5">Pastoral</AccordionHeader>
                      <AccordionBody accordionId="5"></AccordionBody>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionHeader targetId="6">
                        Third Age and Elderly
                      </AccordionHeader>
                      <AccordionBody accordionId="6"></AccordionBody>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionHeader targetId="7">Biblical</AccordionHeader>
                      <AccordionBody accordionId="7"></AccordionBody>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionHeader targetId="8">
                        Scholarships available
                      </AccordionHeader>
                      <AccordionBody accordionId="8"></AccordionBody>
                    </AccordionItem>
                  </Accordion>
                </div>
                <br />
                <button className="btn btn-primary rounded-0">Read More</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default About;
