import React from "react";
import Header from "../components/UI/Header/Header";
import Footer from "../components/UI/Footer/Footer";

const LiveStream = () => {
  return (
    <>
      <Header />
      <div style={{ backgroundColor: "#f3f3f3", marginTop: "100px" }}>
        <h2>Live Stream</h2>
        <video width="100%" height="auto" controls autoPlay>
          <source
            src="http://server.marymercydisciples.com/hls/live.m3u8"
            type="application/x-mpegURL"
          />
          Your browser does not support the video tag.
        </video>
      </div>
      <Footer />
    </>
  );
};

export default LiveStream;
