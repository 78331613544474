import React from "react";

import Section from "../../../HOC/Section";

const Service = () => {
  return (
    <Section id="services">
      <div className="container pt-2 pb-5">
        <div className="section-header pt-5 pb-5 text-center">
          <h3 className="section-title">
            <span>Our </span>Services
          </h3>
          <h6 className="section-subtitle mr-auto ml-auto">
            Individualized quality care that meets the total needs of the
            patient Individualized quality care that quality care that
            Individualized quality care that meets the total.
          </h6>
        </div>
        <div className="section-content">
          <div className="row">
            <div className="col-md-6 col-lg-4 mb-3">
              <div className="service-box d-flex">
                <div className="service-icon mr-4">
                  <i className="fas fa-briefcase" />
                </div>
                <div className="service-body">
                  <h5 className="service-title">Certificate programmes</h5>
                  <p className="service-description">
                    Provide certificate programmes in pioneering mission,
                    missional movement and networks
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 mb-3">
              <div className="service-box d-flex">
                <div className="service-icon mr-4">
                  <i className="fas fa-chart-bar" />
                </div>
                <div className="service-body">
                  <h5 className="service-title">Leadership Training</h5>
                  <p className="service-description">
                    Provide leadership Training, Biblical Theology and Christian
                    mentoring
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 mb-3">
              <div className="service-box d-flex">
                <div className="service-icon mr-4">
                  <i className="fas fa-fist-raised" />
                </div>
                <div className="service-body">
                  <h5 className="service-title">
                    Education in Christian Theology
                  </h5>
                  <p className="service-description">
                    Provides Education in Christian Theology, Church Leadership,
                    Worship and Christian Evangelism
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 mb-3">
              <div className="service-box d-flex">
                <div className="service-icon mr-4">
                  <i className="far fa-paper-plane" />
                </div>
                <div className="service-body">
                  <h5 className="service-title">Flexible Course Design</h5>
                  <p className="service-description">
                    Flexible Course Design to Equip and Train the whole Church
                    for mission and Ministry
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 mb-3">
              <div className="service-box d-flex">
                <div className="service-icon mr-4">
                  <i className="fas fa-gem" />
                </div>
                <div className="service-body">
                  <h5 className="service-title">Solid Foundation</h5>
                  <p className="service-description">
                    Solid Foundation in Theology and Biblical Studies alongside
                    practical Instructions and Experience in Ministry
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 mb-3">
              <div className="service-box d-flex">
                <div className="service-icon mr-4">
                  <i className="far fa-life-ring" />
                </div>
                <div className="service-body">
                  <h5 className="service-title">Suppport Team</h5>
                  <p className="service-description">
                    Provide a place of Equipping and training for all persons to
                    equip people for ministry and mission
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default Service;
