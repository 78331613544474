import React, { useState, useEffect } from "react";
import { Link as Ln } from "react-router-dom";
import Link from "../Link/Link";
import { auth } from "../../../config/firebase";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const Nav = () => {
  const [navClass, setNavClass] = useState("");
  const [toggeledNav, settoggeledNav] = useState(false);
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    // Firebase listener to check authentication status
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });

    return () => unsubscribe(); // Clean up listener on unmount
  }, []);

  const handleLogout = async () => {
    try {
      await auth.signOut();
      setUser(null);
      toast.success("logged out successfully");
      navigate("/");
    } catch (error) {
      // console.error("Failed to log out", error);
      toast.error("failed");
    }
  };

  const toggleNav = () => {
    settoggeledNav(!toggeledNav);
  };

  useEffect(() => {
    window.addEventListener("scroll", () => {
      let navClass = "";
      if (window.scrollY >= 200) {
        navClass = "scrolled";
      }
      setNavClass(navClass);
    });
  }, []);
  return (
    <nav className={`navbar navbar-expand-md bg-light ${navClass}`}>
      <div className="container">
        <a className="navbar-brand" href="/">
          <span>Mary Mercy Disciples</span>
          <i className="fas fa-circle ml-1" />
        </a>
        <div
          className={`navbar-toggler nav-icon ${(() => {
            if (toggeledNav) return "open";
            return "";
          })()}`}
          onClick={toggleNav}
        >
          <span />
          <span />
          <span />
        </div>

        <div
          className={`collapse navbar-collapse ${(() => {
            if (toggeledNav) return "show";
            return "";
          })()}`}
        >
          <ul className="navbar-nav ml-auto">
            {user ? (
              <>
                <li className="nav-item">
                  <Ln to="/files" className="nav-link">
                    videos
                  </Ln>
                </li>
                <li className="nav-item">
                  <Ln className="nav-link" onClick={handleLogout}>
                    Logout
                  </Ln>
                </li>
              </>
            ) : (
              <>
                <li className="nav-item">
                  <Link target="home" offset={-120} classes="nav-link">
                    Home
                  </Link>
                </li>
                <li className="nav-item">
                  <Link target="about" classes="nav-link">
                    About
                  </Link>
                </li>
                <li className="nav-item">
                  <Link target="services" classes="nav-link">
                    Services
                  </Link>
                </li>
                <li className="nav-item">
                  <Link target="blog" classes="nav-link">
                    Blog
                  </Link>
                </li>
                <li className="nav-item">
                  <Link target="contact" classes="nav-link">
                    Contact
                  </Link>
                </li>
                <li className="nav-item">
                  <Ln to="/login" className="nav-link">
                    login
                  </Ln>
                </li>
              </>
            )}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Nav;
