import React from "react";

import Section from "../../../HOC/Section";

const contact = () => {
  return (
    <Section id="contact">
      <div className="container pt-2 pb-5">
        <div className="section-header pt-5 pb-5 text-center">
          <h3 className="section-title">
            <span>Contact </span>Us
          </h3>
          <h6 className="section-subtitle mr-auto ml-auto">
            Feel free to get in touch with Mary Mercy Disciples
          </h6>
        </div>
        <div className="section-content">
          <div className="row">
            <div className="col-md-9 col-lg-7 mr-auto ml-auto">
              <form>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control rounded-0"
                    aria-describedby="emailHelp"
                    placeholder="Enter Name..."
                  />
                </div>
                <div className="form-group">
                  <input
                    type="email"
                    className="form-control rounded-0"
                    aria-describedby="emailHelp"
                    placeholder="Enter email..."
                  />
                </div>
                <div className="form-group">
                  <textarea
                    className="form-control rounded-0"
                    rows="5"
                    placeholder="Enter Message..."
                  />
                </div>
                <div className="form-group text-center">
                  <button className="btn btn-block btn-primary rounded-0 mr-auto ml-auto">
                    Send
                  </button>
                </div>
                <hr />
                <div className="footer-content">
                  <p className="">
                    <small>Address : Nigeria</small>
                  </p>
                  <p className="">
                    <small>Phone : +234 (0) 708 666 5310</small>
                  </p>
                  <p className="">
                    <small>E-mail : oyepretty2000@yahoo.com</small>
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default contact;
