import React from "react";
import { Link as Ln } from "react-router-dom";
import Link from "../Link/Link";

const footer = () => {
  return (
    <footer className="">
      <div className="container text-light pt-5">
        <div className="row">
          <div className="col-sm-6 col-md-6 col-lg-4 mb-5">
            <div className="footer-title">
              <h6>About Us</h6>
            </div>
            <div className="footer-content">
              <p>
                <small className="">
                  We inculcate and teaches the act of leading worship services
                  within a church,this involves leading hymns, prayers, and
                  sermons, as well as organizing and planning worship services.
                  The worship leader is typically responsible for creating a
                  worship experience that is engaging, meaningful, and aligned
                  with the church’s theological and cultural values. We train
                  the worship leader on how to plan and execute worship services
                  such as selecting and arranging songs, choosing readings and
                  prayers, and coordinating the use of multimedia elements like
                  slideshows or videos. Our objective : Is to create an
                  environment where worshipers can connect with God and with one
                  another. This involves setting a positive and inclusive tone,
                  encouraging participation and engagement, and providing
                  opportunities for worshipers to express their faith.
                </small>
              </p>
              <button className="btn btn-sm btn-primary rounded-0">
                Learn more
              </button>
            </div>
          </div>
          <div className="col-sm-6 col-md-6 col-lg-2 mb-5">
            <div className="footer-title">
              <h6>Quick Links</h6>
            </div>
            <div className="footer-content">
              <ul className="list-group quick-links">
                <li>
                  <Link target="home" offset={-120}>
                    Home
                  </Link>
                </li>
                <li>
                  <Link target="about">About</Link>
                </li>
                <li>
                  <Link target="services">Services</Link>
                </li>
                <li>
                  <Link target="blog">Blog</Link>
                </li>
                <li>
                  <Link target="contact">Contact</Link>
                </li>
                <li className="nav-item">
                  <Ln to="/admin-login" className="nav-link">
                    Admin
                  </Ln>
                </li>
              </ul>
            </div>
          </div>
          {/* <div className="col-sm-6 col-md-6 col-lg-3 mb-5">
            <div className="footer-title">
              <h6>Latest News</h6>
            </div>
            <div className="footer-content">
              <p>
                <small className="">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                </small>
              </p>
              <p>
                <small className="">
                  Pellentesque et pulvinar enim. Quisque at tempor ligula Natus
                  error sit voluptatem
                </small>
              </p>
              <p>
                <small className="">accusantium doloremque</small>
              </p>
            </div>
          </div> */}
          <div className="col-sm-6 col-md-6 col-lg-3 mb-5">
            <div className="footer-title">
              <h6>Contact Us</h6>
            </div>
            <div className="footer-content">
              <p className="">
                <small>Address : Nigeria</small>
              </p>
              <p className="">
                <small>Phone : +234 (0) 708 666 5310</small>
              </p>
              <p className="">
                <small>E-mail : oyepretty2000@yahoo.com</small>
              </p>
              <div className="social-media mt-4">
                <a href="!#" className="text-light">
                  <i className="fab fa-facebook-f mr-4" />
                </a>
                <a href="!#" className="text-light">
                  <i className="fab fa-twitter mr-4" />
                </a>
                <a href="!#" className="text-light">
                  <i className="fab fa-instagram mr-4" />
                </a>
                <a href="!#" className="text-light">
                  <i className="fab fa-github" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bottom-footer pt-3 pb-3 text-center">
        <small>©2024 Mery Mercy Disciples. All Right Reserved.</small>
      </div>
    </footer>
  );
};

export default footer;
