import React, { useState } from "react";
import { FaGoogle } from "react-icons/fa";
import toast from "react-hot-toast";
import {
  CustomButton,
  CustomButtonGroup,
} from "../../components/custom/CustomButton";
import CustomForm from "../../components/custom/CustomForm";
import CustomCard from "../../components/custom/CustomCard";
import { Link, useNavigate } from "react-router-dom";
import { auth, googleProvider } from "../../config/firebase";
import { createUserWithEmailAndPassword, signInWithPopup } from "firebase/auth";

export default function SignIn() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const formFields = [
    {
      label: "Email Address",
      type: "email",
      id: "email",
      placeholder: "Enter email",
      value: formData.email,
      onChange: handleInputChange,
    },
    {
      label: "Password",
      type: "password",
      id: "password",
      placeholder: "*****",
      value: formData.password,
      onChange: handleInputChange,
    },
  ];

  const handleSocialLogin = async (e) => {
    // Implement social media login logic here
    e.preventDefault();
    try {
      await signInWithPopup(auth, googleProvider);
      // console.log("Form submitted:", formData);
      navigate("/files");
      toast.success("Logged in successfully");
    } catch (err) {
      toast.error("error");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await createUserWithEmailAndPassword(
        auth,
        formData.email,
        formData.password
      );
      // console.log("Form submitted:", formData);
      setFormData({
        email: "",
        password: "",
      });
      navigate("/files");
      toast.success("Form submitted");
    } catch (err) {
      // toast.error(err);
      // console.log(err.data);
    }
  };

  return (
    <div style={{ marginTop: "90px" }}>
      <div className="flex flex-col max-w-md p-6 rounded-md sm:p-10 dark:bg-gray-900 dark:text-gray-100">
        <CustomCard header="Sign in" subtitle="Sign in to access your account">
          <CustomForm formFields={formFields} onSubmit={handleSubmit} />
          {/* <Link to="/register">Don't have an account, click here</Link> */}
          <hr />
          <CustomButton
            type="filled"
            className="w-100 mb-3"
            color="#A78BFA"
            onClick={handleSocialLogin}
          >
            <FaGoogle /> Login with Google
          </CustomButton>
        </CustomCard>
      </div>
    </div>
  );
}
