import React, { useEffect, useState } from "react";
import { auth } from "../../../config/firebase";
import Section from "../../../HOC/Section";
import bgImage from "../../../assets/img/home_bg.jpg";
import Link from "../../UI/Link/Link";
import { Link as Lnk } from "react-router-dom";

const Home = () => {
  const [user, setUser] = useState(null);
  useEffect(() => {
    // Firebase listener to check authentication status
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });

    return () => unsubscribe(); // Clean up listener on unmount
  }, []);
  return (
    <Section id="home">
      <div>
        <div
          className="home-content p-5"
          style={{ backgroundImage: `url(${bgImage})` }}
        >
          <div className="intro container text-center text-light">
            <h1 className="title">WELCOME TO MARY MERCY DISCIPLES</h1>
            <h2 className="sub-title mb-4">
              Providing world class and effective disciples for Christ,
              effective and affordable for individuals.
            </h2>
            {user ? (
              <Lnk to="/files" className="btn btn-primary rounded-0 mr-2">
                Explore videos
              </Lnk>
            ) : (
              <Lnk to="/login" className="btn btn-primary rounded-0 mr-2">
                Join Us
              </Lnk>
            )}

            <Link target="contact" classes="btn btn-light text-dark rounded-0">
              Contact Us
            </Link>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default Home;
