import React, { useState } from "react";
import axios from "axios";

const FileDownload = () => {
  const [filename, setFilename] = useState("");

  const handleFileDownload = async () => {
    if (!filename) {
      alert("Please enter a filename");
      return;
    }

    try {
      const response = await axios.get(
        `https://server.marymercydisciples.com/download/${filename}`,
        {
          responseType: "blob",
        }
      );
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error("Error downloading file:", error);
      alert("Error downloading file");
    }
  };

  return (
    <div>
      <h2>Download a File</h2>
      <input
        type="text"
        value={filename}
        onChange={(e) => setFilename(e.target.value)}
        placeholder="Enter filename"
      />
      <button onClick={handleFileDownload}>Download</button>
    </div>
  );
};

export default FileDownload;
