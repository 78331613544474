import React, { useEffect, useState } from "react";
import axios from "axios";
import Header from "../components/UI/Header/Header";
import Footer from "../components/UI/Footer/Footer";
import { Spinner } from "reactstrap";

const FileList = () => {
  const [files, setFiles] = useState([]);
  const [error, setError] = useState(null); // State for storing error

  useEffect(() => {
    const fetchFiles = async () => {
      try {
        const response = await axios.get(
          "https://server.marymercydisciples.com/files",
          {
            headers: {
              "Content-Type": "application/json", // Set content type as JSON
            },
          }
        );
        console.log("Fetched files:", response.data);
        setFiles(response.data);
      } catch (error) {
        console.error("Error fetching file list:", error);
        setError("Failed to fetch files. Please try again."); // Set error state
      }
    };

    fetchFiles();
  }, []);

  return (
    <>
      <Header />
      <div style={{ backgroundColor: "#f3f3f3" }}>
        <div style={{ marginTop: "100px" }}>
          <h2>File List</h2>
          {error ? ( // Check if there's an error
            <div className="text-danger">{error}</div>
          ) : files.length === 0 ? (
            <>
              <Spinner>Loading...</Spinner>
              <p>Please wait... Loading the files</p>
            </>
          ) : (
            <ul style={{ listStyleType: "none" }}>
              {files.map((file, index) => {
                const fileKey = file.key || ""; // Ensure `key` exists
                return (
                  <li key={index}>
                    {fileKey.endsWith(".mp4") ? (
                      <>
                        <video width="320" height="240" controls>
                          <source src={file.url} type="video/mp4" />
                          Your browser does not support the video tag.
                        </video>
                        <h5>{fileKey}</h5>
                      </>
                    ) : (
                      <div className="col-lg-4 mb-3">
                        <div className="rounded-0">
                          <img
                            src={file.url}
                            alt={fileKey}
                            width="320"
                            height="240"
                          />
                          <div className="">
                            <h5>{fileKey}</h5>
                          </div>
                        </div>
                      </div>
                    )}
                    <a
                      href={file.url}
                      download={fileKey}
                      className="btn btn-sm btn-primary"
                    >
                      Download
                    </a>
                  </li>
                );
              })}
            </ul>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default FileList;
